<template>
  <v-container class="d-flex justify-center align-center" fluid>
    <!-- Indicador de carga -->
    <v-overlay :value="loading" absolute>
      <v-progress-circular
        indeterminate
        color="yellow"
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <!-- Contenido del card cuando hay datos -->
    <v-card v-if="!loading && datosQR.length > 0" class="mx-auto custom-card-width">
      <v-card-title class="yellow darken-3 white--text d-flex flex-column justify-center align-center">
        <h3 class="no-margin font-weight-bold">{{ datosQR[0].nombre }}</h3>
        <span class="subtitle-1">{{ datosQR[0].cargo }}</span>
      </v-card-title>

      <v-card-text class="pa-0 yellow darken-3 white--text">
        <v-row no-gutters>
          <v-col cols="4" class="text-center py-2">
            <v-btn icon large @click="callPhoneNumber">
              <v-icon large style="color: white">mdi-phone</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4" class="text-center py-2">
            <v-btn icon large @click="sendEmail">
              <v-icon large style="color: white">mdi-email</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4" class="text-center py-2">
            <v-btn icon large @click="openMaps">
              <v-icon large style="color: white">mdi-map-marker</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <v-list-item class="mb-4">
          <v-list-item-icon>
            <v-icon>mdi-cellphone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title @click="callPhoneNumber" style="cursor:pointer">+{{ datosQR[0].celular }}</v-list-item-title>
            <v-list-item-subtitle>Celular</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="mb-4">
          <v-list-item-icon>
            <v-icon>mdi-email</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title @click="sendEmail" style="cursor:pointer">{{ datosQR[0].correo }}</v-list-item-title>
            <v-list-item-subtitle>Email</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="mb-4">
          <v-list-item-icon>
            <v-icon>mdi-briefcase</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Abracol S.A</v-list-item-title>
            <v-list-item-subtitle>{{ datosQR[0].cargo }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="mb-4">
          <v-list-item-icon>
            <v-icon>mdi-map-marker</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title @click="openMaps" style="cursor:pointer">
              Autopista Norte Km 20, Girardota, Antioquia 6553, Colombia
            </v-list-item-title>
            <v-list-item-subtitle><a style="text-decoration: none; color: #ff3800" href="https://maps.app.goo.gl/MrWZc1PD4XQxcyzX7" target="_blank">Mostrar en mapa</a></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="mb-4">
          <v-list-item-icon>
            <v-icon>mdi-web</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title @click="openSiteWeb" style="cursor:pointer">Abracol S.A</v-list-item-title>
            <v-list-item-subtitle>Sitio web</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-menu offset-y left transition="slide-x-reverse-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              large
              class="mr-4 custom-icon-btn"
              style="background-color: #fdd835; border-radius: 50%; width: 56px; height: 56px;"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="custom-icon" style="color: white;">mdi-account-plus</v-icon>
            </v-btn>

          </template>

          <v-list>
            <v-list-item @click="showEmailModal">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Enviar por correo</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="saveContact">
              <v-list-item-icon>
                <v-icon>mdi-account-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Guardar contacto en mi teléfono</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>

      <!-- Email modal -->
      <v-dialog v-model="emailModal" max-width="400">
        <v-card>
          <v-card-title class="headline">Enviar contacto a:</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="emailAddress"
              label="Dirección de email"
              type="text"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="sendEmailWithTemplate">Enviar</v-btn>
            <v-btn @click="emailModal = false">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text class="pa-4 white--text text-center">
        <div class="d-flex justify-center">
          <img class="mx-auto" src="../assets/banner.jpg" alt="">
        </div>
      </v-card-text>
    </v-card>

    <!-- Contenido del card cuando no hay datos -->
    <v-card v-if="!loading && datosQR.length === 0" class="mx-auto custom-card-width">
      <v-card-title class="yellow darken-3 white--text d-flex flex-column justify-center align-center">
        <h3 class="no-margin font-weight-bold">¡No se encontró el contacto!</h3>
        <span class="subtitle-1">Verifica la información y vuelve a intentarlo.</span>
      </v-card-title>

      <v-card-text class="pa-2 d-flex flex-column justify-center align-center">
        <v-icon large class="mb-3">mdi-alert-circle-outline</v-icon>
        <p class="text-center">Lo sentimos, no hemos podido encontrar la información solicitada. Asegúrate que el contacto exista.</p>
        <v-btn color="yellow darken-2" @click="close">Cerrar</v-btn>
      </v-card-text>
      <v-card-text class="pa-4 white--text text-center">
        <div class="image-container">
          <img src="../assets/banner.jpg" alt="Banner">
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios';
  export default {
    data() {
      return {
        datosQR: [],
        emailModal: false,
        emailAddress: '',
        loading: false, // Estado de carga
      };
    },
    mounted() {
      this.consultarInformacion();
    },
    methods: {
      async consultarInformacion() {
        this.loading = true; // Mostrar indicador de carga
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const t = urlParams.get('t');
        const id = this.$route.params.id;
        try {
          const solicitud = await axios.get('https://qr.abracol.co/QR/GetData', {
            params: {
              t: id,
            }
          });
          this.datosQR = solicitud.data.result || [];
          if (this.datosQR.length === 0) {
            console.error('No se encontraron datos');
          }
        } catch (error) {
          console.error('Error al consultar la información:', error);
        } finally {
          this.loading = false;
        }
      },

    callPhoneNumber() {
      window.location.href = `tel:+${this.datosQR[0].celular}`;
    },
    sendEmail() {
      const email = `${this.datosQR[0].correo}`;
      const subject = 'Asunto del correo';
      const body = '';
      window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    },
    openMaps() {
      window.open('https://maps.app.goo.gl/MrWZc1PD4XQxcyzX7');
    },
    openSiteWeb() {
      window.open('https://abracol.com');
    },
    async saveContact() {
      const contact = this.datosQR[0];
      const vCard = `BEGIN:VCARD
        VERSION:3.0
        FN:${contact.nombre}
        ORG:Abracol S.A
        TEL;TYPE=CELL:+${contact.celular}
        EMAIL:${contact.correo}
        ADR;TYPE=WORK:;;Autopista Norte Km 20;Girardota;Antioquia;6553;Colombia
        END:VCARD`;
      const encodedUri = `data:text/vcard;charset=utf-8,${encodeURIComponent(vCard)}`;
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${contact.nombre.replace(/[^a-zA-Z0-9]/g, '_')}.vcf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    showEmailModal() {
      this.emailModal = true;
    },
    async sendEmailWithTemplate() {
      try{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const t = urlParams.get('t');
        const id = this.$route.params.id;
        const email = this.emailAddress;
        const response = await axios.post('https://qr.abracol.co/QR/sendMail', {
          t: id,
          to: email
        });
        this.emailModal = false;
      }catch(ex){
        console.error(ex)
      }
      
    },
    close() {
      window.location.href = 'about:blank';
    }
  }
}

</script>
<style scoped>
  .custom-card-width {
      width: 100%;
      max-width: 600px;
  }
  
  .no-margin {
      margin: 0;
  }
  
  .yellow--text {
      color: #FFD700;
  }
  
  .red--text {
      color: #FF5252;
  }
  
  .v-card-title h3,
  .v-card-title .subtitle-1,
  .v-btn {
      font-size: 1.2rem;
  }
  
  @media (max-width: 600px) {
      .v-card-title h3 {
      font-size: 1.5rem;
      }
  
      .v-card-title .subtitle-1 {
      font-size: 1rem;
      }
  
      .v-btn {
      font-size: 1.5rem;
      }
  }
  .custom-icon-btn {
    position: relative;
    margin-top: -65px
  }

  .image-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
}

</style>
